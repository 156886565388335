import { useEffect, useState } from "react";
import config from "../config";
import { subSquidQuery } from "../libs/subsquid";
import EventsFilter from "../screens/events/events-filter";
import EventsList from "../screens/events/events-list";

export default function Events() {
  const limit = config.ITEMS_PER_PAGE;
  const [filterParams, setFilterParams] = useState({
    module: "all",
    eventMethod: "all",
    timeDimension: "date", // date, block
    startDate: "",
    endDate: "",
    startBlock: "",
    endBlock: "",
  });

  const [events, setEvents] = useState([]);
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageOffset, setPageOffset] = useState(0);

  const changeFilterParams = (param, value) => {
    setFilterParams({ ...filterParams, [param]: value });
  };

  useEffect(() => {
    const after = (pageNumber - 1) * limit <= 0 ? 1 : (pageNumber - 1) * limit;
    const query = `{
    eventsConnection(orderBy: block_timestamp_DESC, first: ${limit}, after: "${after}") {
    edges {
      node {
        id
        indexInBlock
        name
        block {
          timestamp
          height
        }
        phase
        pos
        extrinsic {
          indexInBlock
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
  }`;
    let isListMounted = true;
    const getEvents = async () => {
      setIsLoadingEvents(true);
      const { data } = await subSquidQuery.post("", {
        query,
      });
      let events = data.data.eventsConnection.edges.map((e) => ({
        ...e.node,
        eventId: `${e.node.block.height}-${e.node.indexInBlock}`,
        extrinsicId: `${e.node.block.height}-${e.node.extrinsic.indexInBlock}`,
        action: `${e.node.name}`,
        blockNumber: e.node.block.height,
        blockTimestamp: e.node.block.timestamp,
      }));

      if (isListMounted) {
        setEvents(events);
        setIsLoadingEvents(false);
      }
    };

    getEvents();
    return () => (isListMounted = false);
  }, [filterParams, limit, pageNumber]);

  return (
    <div className="page mb-40">
      <h3 className=".text-accent-purple">Event history</h3>
      {/* <div className="bordered-content-box mb-40">
        <EventsFilter
          params={filterParams}
          changeFilterParams={changeFilterParams}
          setFilterParams={setFilterParams}
          setPageNumber={setPageNumber}
        />
      </div> */}
      <EventsList
        events={events}
        isLoadingEvents={isLoadingEvents}
        pageOffset={pageOffset}
        setPageNumber={setPageNumber}
        setPageOffset={setPageOffset}
      />
      <div className="mt-40 mb-40"></div>
    </div>
  );
}
