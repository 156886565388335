import { useState } from "react";
import { Link } from "react-router-dom";
import { copyText } from "../utils";
import FormatedTime from "./formated-time";
import JsonToTable from "./json-to-table";

export default function ExpandableRow({
  eventId,
  blockNumber,
  blockTimestamp,
  extrinsicId,
  action,
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCodeViewOpen, setIsCodeViewOpen] = useState(false);
  const toggleIsExpanded = () => setIsExpanded((isExpanded) => !isExpanded);
  const toggleIsCodeViewOpen = () =>
    setIsCodeViewOpen((isCodeViewOpen) => !isCodeViewOpen);
  return (
    <>
      <tr key={eventId}>
        <td className="text-accent-purple no-wrap">
          {extrinsicId ? (
            <Link to={`/extrinsic/${extrinsicId}?event=${eventId}`}>
              {eventId}
            </Link>
          ) : (
            eventId
          )}
        </td>
        <td className="text-accent-purple">
          <Link to={`/block/${blockNumber}`}>{blockNumber}</Link>
        </td>

        <td className="text-accent-purple no-wrap">
          {extrinsicId ? (
            <Link to={`/extrinsic/${extrinsicId}`}>{extrinsicId}</Link>
          ) : (
            "-"
          )}
        </td>

        <td className="text-dark-white">{new Date(blockTimestamp).toLocaleString()}</td>

        <td className="text-accent-purple">{action}</td>
      </tr>
    </>
  );
}
