import { Link } from "react-router-dom";
import { copyText } from "../../utils";
import FormatedTime from "../formated-time";
import InfoPlaceholder from "../info-placeholder";
import Pagination from "../pagination/pagination";
import { useEffect, useState } from "react";
import config from "../../config";
import { subSquidQuery } from "../../libs/subsquid";
import { shortenHex } from "../../utils";

export default function ExtrinsicsList() {
  const limit = config.ITEMS_PER_PAGE;
  const maxPageSize = config.MAX_PAGE_SIZE;
  const [pageCount, setPageCount] = useState(1);
  const [pageOffset, setPageOffset] = useState(0);
  const [isFetchingExtrinsics, setIsFetchingExtrinsics] = useState(false);
  const [extrinsics, setExtrinsics] = useState([]);
  const [endCursor, setEndCursor] = useState(0);

  useEffect(() => {
    setIsFetchingExtrinsics(true);
    let isListMounted = true;
    const getExtrinsics = async () => {
      const QUERY = `{
        ${
          endCursor > 0
            ? `extrinsicsConnection(first: ${limit}, orderBy: block_timestamp_DESC, after: "${endCursor}")`
            : `extrinsicsConnection(first: ${limit}, orderBy: block_timestamp_DESC)`
        } {
          edges {
            cursor
            node {
              indexInBlock
              success
              block {
                timestamp
                height
              }
              hash
              call {
                name
              }
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          totalCount
        }
      }`;
      const { data } = await subSquidQuery.post("", {
        query: QUERY,
      });
      setIsFetchingExtrinsics(false);

      if (data.errors) {
        console.error(data.errors);
      } else {
        const { edges, totalCount } = data.data.extrinsicsConnection;
        let pageCount = Math.ceil(totalCount / limit);
        if (pageCount >= maxPageSize) pageCount = maxPageSize;

        const extrinsic = edges.map((e) => ({
          ...e.node,
          extrinsicId: `${e.node.block.height}-${e.node.indexInBlock}`,
          blockNumber: e.node.block.height,
          hash: e.node.hash,
          time: e.node.block.timestamp,
          action: e.node.call.name,
        }));
        if (isListMounted) {
          setExtrinsics(extrinsic);
          setPageCount(pageCount);
        }
      }
    };

    getExtrinsics();
    return () => (isListMounted = false);
  }, [endCursor, limit, maxPageSize]);

  // Invoke when user click to request another page.
  const handlePageClick = ({ selected }) => {
    const cursor = selected <= 0 ? "1" : String(selected * 10);
    setEndCursor(cursor);
    setPageOffset(selected);
  };

  return (
    <>
      {isFetchingExtrinsics ? (
        <InfoPlaceholder text="Loading extrinsics..." isListContainer />
      ) : (
        <>
          <div className="extrinsics-list bordered-content-box scroll-x">
            <table className="table">
              <thead>
                <tr>
                  <th className="no-wrap">Extrinsic Id</th>
                  <th>Block</th>
                  <th>Hash</th>
                  <th>Time</th>
                  <th>Action</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {extrinsics.map(
                  ({
                    extrinsicId,
                    blockNumber,
                    hash,
                    time,
                    action,
                    extrinsicJSON,
                  }) => (
                    <tr key={extrinsicId}>
                      <td className="text-accent-purple no-wrap">
                        <Link to={`/extrinsic/${extrinsicId}`}>
                          {extrinsicId}
                        </Link>
                      </td>
                      <td className="text-accent-purple">
                        <Link to={`/block/${blockNumber}`}>{blockNumber}</Link>
                      </td>

                      <td className="text-accent-purple">{shortenHex(hash)}</td>

                      <td className="text-dark-white">
                        <FormatedTime time={new Date(time)} />
                      </td>

                      {action && (
                        <td className="text-accent-purple">{action}</td>
                      )}

                      {extrinsicJSON && (
                        <td className="no-wrap">
                          <span>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="mr-3 pointer"
                              onClick={() => copyText(extrinsicJSON)}
                            >
                              <path
                                d="M17.9091 19.3636H8.90909V7.90909H17.9091V19.3636ZM17.9091 6.27273H8.90909C8.4751 6.27273 8.05888 6.44513 7.75201 6.75201C7.44513 7.05888 7.27273 7.4751 7.27273 7.90909V19.3636C7.27273 19.7976 7.44513 20.2138 7.75201 20.5207C8.05888 20.8276 8.4751 21 8.90909 21H17.9091C18.3431 21 18.7593 20.8276 19.0662 20.5207C19.3731 20.2138 19.5455 19.7976 19.5455 19.3636V7.90909C19.5455 7.4751 19.3731 7.05888 19.0662 6.75201C18.7593 6.44513 18.3431 6.27273 17.9091 6.27273ZM15.4545 3H5.63636C5.20237 3 4.78616 3.1724 4.47928 3.47928C4.1724 3.78616 4 4.20237 4 4.63636V16.0909H5.63636V4.63636H15.4545V3Z"
                                fill="#979798"
                              />
                            </svg>
                          </span>
                        </td>
                      )}
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end">
            <div></div> {/* Downloads component  */}
            <Pagination
              pageCount={pageCount}
              pageOffset={pageOffset}
              handleChangePage={handlePageClick}
            />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
        </>
      )}
    </>
  );
}
