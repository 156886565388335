import Blocks from "../components/blocks";
import Snapshot from "../components/snapshot";
import BlocksExtrinsics from "../components/blocks-extrinsics";
// import Validators from "../components/validators/validators";

export default function Home() {
  return (
    <div className="page">
      <Snapshot />
      <Blocks />
      <BlocksExtrinsics />
      {/* <Validators /> */}
    </div>
  );
}
