import { useEffect, useState } from "react";
import { useSubstrateState } from "../libs/substrate";
import { Link } from "react-router-dom";
import config from "../config";
import FormatedTime from "./formated-time";
import { copyText } from "../utils";
import { shortenHex } from "../utils";

export default function BlocksExtrinsics() {
  const { api } = useSubstrateState();
  const [extrinsics, setExtrinsics] = useState([]);

  useEffect(() => {
    let unsubscribeAll = null;
    let extrinsicsList = [];
    const getExtrinsics = async () => {
      unsubscribeAll = await api.rpc.chain.subscribeAllHeads((header) => {
        const blockNumber = header.number.toNumber();
        const hash = header.hash.toHex();
        // Get number of extrinsics
        api.rpc.chain
          .getBlock(hash)
          .then((signedBlock) => {
            const block = signedBlock.toHuman().block;
            const extrinsicsCount = block.extrinsics.length;
            let time = Number(
              block.extrinsics[0].method.args.now.replace(/,/g, "")
            );
            block.extrinsics.forEach((extrinsic, i) => {
              const ext = {
                extrinsicId: `${blockNumber}-${i}`,
                blockNumber: blockNumber,
                hash:
                  shortenHex(signedBlock.block.extrinsics[i].hash.toHex()) ||
                  "-",
                time,
                isSigned: extrinsic.isSigned,
                action: `${extrinsic.method.section} (${extrinsic.method.method})`,
                module: extrinsic.method.section,
                extrinsicJSON: JSON.stringify(extrinsic),
              };
              if (extrinsicsList.indexOf(ext) === -1) {
                const newTransferEvents = extrinsicsList.slice(0, 9);
                newTransferEvents.unshift(ext);
                extrinsicsList = newTransferEvents;
              }
            });
          })
          .catch(console.error);
        setExtrinsics(extrinsicsList.slice(0, 10));
      });
      return unsubscribeAll;
    };

    getExtrinsics();
    return () => unsubscribeAll && unsubscribeAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="validators-container">
      <div className="d-flex justify-content-space-between">
        <div className="d-flex align-items-center">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 16C13.1 16 14 16.9 14 18C14 19.1 13.1 20 12 20C10.9 20 10 19.1 10 18C10 16.9 10.9 16 12 16ZM12 10C13.1 10 14 10.9 14 12C14 13.1 13.1 14 12 14C10.9 14 10 13.1 10 12C10 10.9 10.9 10 12 10ZM12 4C13.1 4 14 4.9 14 6C14 7.1 13.1 8 12 8C10.9 8 10 7.1 10 6C10 4.9 10.9 4 12 4ZM6 16C7.1 16 8 16.9 8 18C8 19.1 7.1 20 6 20C4.9 20 4 19.1 4 18C4 16.9 4.9 16 6 16ZM6 10C7.1 10 8 10.9 8 12C8 13.1 7.1 14 6 14C4.9 14 4 13.1 4 12C4 10.9 4.9 10 6 10ZM6 4C7.1 4 8 4.9 8 6C8 7.1 7.1 8 6 8C4.9 8 4 7.1 4 6C4 4.9 4.9 4 6 4ZM18 16C19.1 16 20 16.9 20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16ZM18 10C19.1 10 20 10.9 20 12C20 13.1 19.1 14 18 14C16.9 14 16 13.1 16 12C16 10.9 16.9 10 18 10ZM18 4C19.1 4 20 4.9 20 6C20 7.1 19.1 8 18 8C16.9 8 16 7.1 16 6C16 4.9 16.9 4 18 4Z"
              fill="#706CFF"
            />
          </svg>

          <h3 className="ml-3">Latest Extrinsics</h3>
        </div>
        <a href="/extrinsic" className="button small">
          All
        </a>
      </div>
      {/* <div className="validators-table"> */}
      {/* <div className="extrinsics-list bordered-content-box scroll-x"> */}
      <table>
        <thead>
          <tr>
            <th className="no-wrap">Extrinsic Id</th>
            <th>Block</th>
            <th>Hash</th>
            <th>Time</th>
            <th>Action</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {extrinsics.map(
            ({
              extrinsicId,
              blockNumber,
              hash,
              time,
              isSigned,
              action,
              extrinsicJSON,
            }) => (
              <tr key={hash}>
                <td className="text-accent-purple no-wrap">
                  <Link to={`/extrinsic/${extrinsicId}`}>{extrinsicId}</Link>
                </td>
                <td className="text-accent-purple">
                  <Link to={`/block/${blockNumber}`}>{blockNumber}</Link>
                </td>

                <td className="text-accent-purple">{hash}</td>

                <td className="text-dark-white">
                  <FormatedTime time={new Date(time)} />
                </td>

                <td className="text-accent-purple">{action}</td>

                <td className="no-wrap">
                  <span>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-3 pointer"
                      onClick={() => copyText(extrinsicJSON)}
                    >
                      <path
                        d="M17.9091 19.3636H8.90909V7.90909H17.9091V19.3636ZM17.9091 6.27273H8.90909C8.4751 6.27273 8.05888 6.44513 7.75201 6.75201C7.44513 7.05888 7.27273 7.4751 7.27273 7.90909V19.3636C7.27273 19.7976 7.44513 20.2138 7.75201 20.5207C8.05888 20.8276 8.4751 21 8.90909 21H17.9091C18.3431 21 18.7593 20.8276 19.0662 20.5207C19.3731 20.2138 19.5455 19.7976 19.5455 19.3636V7.90909C19.5455 7.4751 19.3731 7.05888 19.0662 6.75201C18.7593 6.44513 18.3431 6.27273 17.9091 6.27273ZM15.4545 3H5.63636C5.20237 3 4.78616 3.1724 4.47928 3.47928C4.1724 3.78616 4 4.20237 4 4.63636V16.0909H5.63636V4.63636H15.4545V3Z"
                        fill="#979798"
                      />
                    </svg>
                  </span>
                  <span>
                    {/* <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.5 6C7.18182 6 3.49409 8.69533 2 12.5C3.49409 16.3047 7.18182 19 11.5 19C15.8182 19 19.5059 16.3047 21 12.5C19.5059 8.69533 15.8182 6 11.5 6ZM11.5 16.8333C9.11636 16.8333 7.18182 14.892 7.18182 12.5C7.18182 10.108 9.11636 8.16667 11.5 8.16667C13.8836 8.16667 15.8182 10.108 15.8182 12.5C15.8182 14.892 13.8836 16.8333 11.5 16.8333ZM11.5 9.9C10.0664 9.9 8.90909 11.0613 8.90909 12.5C8.90909 13.9387 10.0664 15.1 11.5 15.1C12.9336 15.1 14.0909 13.9387 14.0909 12.5C14.0909 11.0613 12.9336 9.9 11.5 9.9Z"
                        fill="#979798"
                      />
                    </svg> */}
                  </span>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
      {/* </div> */}
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
