const envVarNames = [
  "REACT_APP_APP_NAME",
  "REACT_APP_PROVIDER_SOCKET",
  "REACT_APP_DEVELOPMENT_KEYRING",
  "REACT_APP_ITEMS_PER_PAGE",
  "REACT_APP_MAX_PAGE_SIZE",
  "REACT_APP_TOKEN_VALUE",
  "REACT_APP_SUBSQUID_ARCHIVE_GRAPH_NODE",
  "REACT_APP_SUBSQUID_GRAPH_SERVER",
  "REACT_APP_PROVIDER_SOCKET",
];
const envVars = envVarNames.reduce((mem, n) => {
  if (process.env[n] !== undefined) mem[n.slice(10)] = process.env[n];
  return mem;
}, {});

const config = { ...envVars };
export default config;
