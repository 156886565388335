import React from "react";
import ExtrinsicsList from "../components/extrinsics/list";

export default function Extrinsics() {
  return (
    <div className="page">
      <ExtrinsicsList />
    </div>
  );
}
