import React from "react";
import { Link } from "react-router-dom";
import FormatedTime from "../components/formated-time";
import Pagination from "../components/pagination/pagination";
import { useEffect, useState } from "react";
import config from "../config";
import { subSquidQuery } from "../libs/subsquid";
import InfoPlaceholder from "../components/info-placeholder";

export default function Blocks() {
  const limit = config.ITEMS_PER_PAGE;
  const maxPageSize = config.MAX_PAGE_SIZE;
  const [pageCount, setPageCount] = useState(1);
  const [pageOffset, setPageOffset] = useState(0);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [blocks, setBlocks] = useState([]);
  const [endCursor, setEndCursor] = useState(0);

  useEffect(() => {
    setIsFetchingData(true);
    let isListMounted = true;
    const getBlocks = async () => {
      const QUERY = `{
        ${
          endCursor > 0
            ? `blocksConnection(first: ${limit}, orderBy: timestamp_DESC, after: "${endCursor}")`
            : `blocksConnection(first: ${limit}, orderBy: timestamp_DESC)`
        } {
          edges {
            cursor
            node {
              timestamp
              height
              hash
              events {
                id
              }
              extrinsics {
                id
              }
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          totalCount
        }
      }`;
      const { data } = await subSquidQuery.post("", {
        query: QUERY,
      });
      setIsFetchingData(false);

      if (data.errors) {
        console.error(data.errors);
      } else {
        const { edges, totalCount } = data.data.blocksConnection;
        let pageCount = Math.ceil(totalCount / limit);
        if (pageCount >= maxPageSize) pageCount = maxPageSize;

        const extrinsic = edges.map((e) => ({
          ...e.node,
          blockNumber: e.node.height,
          hash: e.node.hash,
          time: e.node.timestamp,
          extrinsicsCount: e.node.extrinsics.length,
          eventsCount: e.node.events.length,
        }));
        if (isListMounted) {
          setBlocks(extrinsic);
          setPageCount(pageCount);
        }
      }
    };

    getBlocks();
    return () => (isListMounted = false);
  }, [endCursor, limit, maxPageSize]);

  // Invoke when user click to request another page.
  const handlePageClick = ({ selected }) => {
    const cursor = selected <= 0 ? "1" : String(selected * 10);
    setEndCursor(cursor);
    setPageOffset(selected);
  };

  return (
    <div className="page">
      {isFetchingData ? (
        <InfoPlaceholder text="Loading blocks..." isListContainer />
      ) : (
        <>
          <div className="blocks-list">
            <table className="table">
              <thead>
                <tr>
                  <th>Block</th>
                  <th>Time</th>
                  <th>Extrinsics</th>
                  <th>Events</th>
                  <th>Block hash</th>
                </tr>
              </thead>
              <tbody>
                {blocks.map((block) => (
                  <tr key={`${block.blockNumber}-${Math.random()}`}>
                    <td className="">
                      <Link
                        to={`/block/${block.blockNumber}`}
                        className="text-accent-purple"
                      >
                        {block.blockNumber}
                      </Link>
                    </td>
                    <td className="text-dark-white text-small">
                      <div className="d-flex align-items-center">
                        <span className="mr-3">
                          <FormatedTime time={block.time} />
                        </span>
                      </div>
                    </td>
                    <td className="text-accent-purple text-small">
                      {block.extrinsicsCount}
                    </td>
                    <td className="text-accent-purple text-small">
                      {block.eventsCount}
                    </td>
                    <td>
                      <span className="text-accent-purple">
                        {block.hash.slice(0, 10)}...
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end">
            <div></div> {/* Downloads component  */}
            <Pagination
              pageCount={pageCount}
              pageOffset={pageOffset}
              handleChangePage={handlePageClick}
            />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
        </>
      )}
    </div>
  );
}
