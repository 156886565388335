import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      {/* <svg
        width="155"
        height="39"
        viewBox="0 0 155 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="nav-brand"
      >
        <path
          d="M19.9775 13.4799L4.46005 22.4382C2.06668 23.8199 2.06668 26.06 4.46005 27.4417L19.9775 36.3999C22.3709 37.7816 26.2513 37.7816 28.6447 36.3999L44.1621 27.4417C46.5555 26.06 46.5555 23.8199 44.1621 22.4382L28.6447 13.4799C26.2513 12.0982 22.3709 12.0982 19.9775 13.4799Z"
          fill="#4F4DAA"
        />
        <path
          d="M19.9775 2.5881L4.46005 11.5463C2.06668 12.928 2.06668 15.1682 4.46005 16.5499L19.9775 25.5081C22.3709 26.8898 26.2513 26.8898 28.6447 25.5081L44.1621 16.5499C46.5555 15.1682 46.5555 12.928 44.1621 11.5463L28.6447 2.5881C26.2513 1.2064 22.3709 1.2064 19.9775 2.5881Z"
          fill="#706CFF"
        />
        <path
          d="M71.4953 3.95447C73.4737 3.95447 75.2722 4.4298 76.8909 5.38045C78.5353 6.30542 79.82 7.59009 80.7449 9.23447C81.6699 10.8532 82.1324 12.6645 82.1324 14.6686C82.1324 16.6727 81.6956 18.497 80.822 20.1413C79.9741 21.76 78.7922 23.0319 77.2763 23.9568C75.7861 24.8818 74.116 25.3443 72.2661 25.3443C70.7759 25.3443 69.3884 25.0359 68.1037 24.4193C66.8191 23.8027 65.7271 22.9419 64.8278 21.8371V32.243C64.8278 32.8339 64.648 33.3092 64.2883 33.669C63.9286 34.0287 63.4532 34.2085 62.8623 34.2085C62.297 34.2085 61.8217 34.0287 61.4363 33.669C61.0766 33.3092 60.8967 32.8339 60.8967 32.243V14.6686C60.8967 12.6645 61.3592 10.8532 62.2842 9.23447C63.2348 7.59009 64.5067 6.30542 66.0997 5.38045C67.7183 4.4298 69.5169 3.95447 71.4953 3.95447ZM71.4953 21.8757C72.78 21.8757 73.9362 21.5673 74.9639 20.9507C76.0173 20.3084 76.8395 19.4476 77.4305 18.3685C78.0471 17.2637 78.3554 16.0304 78.3554 14.6686C78.3554 13.3069 78.0471 12.0736 77.4305 10.9688C76.8395 9.86396 76.0173 9.00323 74.9639 8.38659C73.9362 7.74425 72.78 7.42308 71.4953 7.42308C70.2106 7.42308 69.0416 7.74425 67.9881 8.38659C66.9604 9.00323 66.151 9.86396 65.5601 10.9688C64.9691 12.0736 64.6737 13.3069 64.6737 14.6686C64.6737 16.0304 64.9691 17.2637 65.5601 18.3685C66.151 19.4476 66.9604 20.3084 67.9881 20.9507C69.0416 21.5673 70.2106 21.8757 71.4953 21.8757Z"
          fill="white"
        />
        <path
          d="M105.045 14.3218C105.045 14.81 104.878 15.221 104.544 15.5551C104.21 15.8634 103.786 16.0175 103.272 16.0175H88.6268C88.9094 17.8161 89.7188 19.2678 91.0548 20.3726C92.4166 21.4774 94.0738 22.0298 96.0265 22.0298C96.7973 22.0298 97.5938 21.8885 98.416 21.6059C99.2639 21.3232 99.9576 20.9764 100.497 20.5653C100.857 20.2827 101.281 20.1413 101.769 20.1413C102.257 20.1413 102.643 20.2698 102.925 20.5267C103.388 20.9121 103.619 21.3489 103.619 21.8371C103.619 22.2996 103.413 22.685 103.002 22.9933C102.129 23.687 101.037 24.2523 99.7264 24.6891C98.4417 25.1259 97.2084 25.3443 96.0265 25.3443C93.9196 25.3443 92.0312 24.8946 90.3611 23.9954C88.691 23.0704 87.3806 21.7986 86.43 20.1799C85.505 18.5612 85.0425 16.7241 85.0425 14.6686C85.0425 12.6132 85.4793 10.7761 86.3529 9.15739C87.2522 7.51301 88.4855 6.24119 90.0528 5.34191C91.6458 4.41695 93.4443 3.95447 95.4484 3.95447C97.4268 3.95447 99.1354 4.39126 100.574 5.26483C102.013 6.13841 103.118 7.35885 103.889 8.92615C104.659 10.4935 105.045 12.292 105.045 14.3218ZM95.4484 7.26892C93.5728 7.26892 92.044 7.78279 90.8621 8.81053C89.7059 9.83827 88.9736 11.2129 88.6653 12.9343H101.499C101.268 11.2129 100.626 9.83827 99.5722 8.81053C98.5188 7.78279 97.1442 7.26892 95.4484 7.26892Z"
          fill="white"
        />
        <path
          d="M108.248 14.9769C108.248 14.4888 108.415 14.0777 108.749 13.7436C109.083 13.4353 109.507 13.2812 110.021 13.2812H124.666C124.384 11.4826 123.574 10.0309 122.238 8.92615C120.877 7.82133 119.219 7.26892 117.267 7.26892C116.496 7.26892 115.699 7.41024 114.877 7.69286C114.029 7.97549 113.336 8.32235 112.796 8.73345C112.436 9.01608 112.012 9.15739 111.524 9.15739C111.036 9.15739 110.651 9.02892 110.368 8.77199C109.906 8.38659 109.674 7.9498 109.674 7.46162C109.674 6.99914 109.88 6.61374 110.291 6.30542C111.165 5.61169 112.256 5.04644 113.567 4.60965C114.852 4.17286 116.085 3.95447 117.267 3.95447C119.374 3.95447 121.262 4.4041 122.932 5.30337C124.602 6.22834 125.913 7.50016 126.863 9.11885C127.788 10.7375 128.251 12.5746 128.251 14.6301C128.251 16.6855 127.814 18.5226 126.94 20.1413C126.041 21.7857 124.808 23.0575 123.24 23.9568C121.647 24.8818 119.849 25.3442 117.845 25.3442C115.866 25.3442 114.158 24.9075 112.719 24.0339C111.28 23.1603 110.175 21.9399 109.405 20.3726C108.634 18.8053 108.248 17.0067 108.248 14.9769ZM117.845 22.0298C119.72 22.0298 121.249 21.5159 122.431 20.4882C123.587 19.4604 124.32 18.0858 124.628 16.3644H111.794C112.025 18.0858 112.668 19.4604 113.721 20.4882C114.774 21.5159 116.149 22.0298 117.845 22.0298Z"
          fill="white"
        />
        <path
          d="M141.43 3.95447C143.408 3.95447 145.194 4.4298 146.787 5.38045C148.405 6.30542 149.677 7.59009 150.602 9.23447C151.553 10.8532 152.028 12.6645 152.028 14.6686V32.243C152.028 32.8339 151.848 33.3092 151.489 33.669C151.129 34.0287 150.654 34.2085 150.063 34.2085C149.497 34.2085 149.022 34.0287 148.637 33.669C148.277 33.3092 148.097 32.8339 148.097 32.243V21.8371C147.198 22.9419 146.106 23.8027 144.821 24.4193C143.536 25.0359 142.149 25.3443 140.659 25.3443C138.809 25.3443 137.126 24.8818 135.61 23.9568C134.12 23.0319 132.938 21.76 132.064 20.1413C131.216 18.497 130.792 16.6727 130.792 14.6686C130.792 12.6645 131.255 10.8532 132.18 9.23447C133.105 7.59009 134.377 6.30542 135.995 5.38045C137.64 4.4298 139.451 3.95447 141.43 3.95447ZM141.43 21.8757C142.714 21.8757 143.87 21.5673 144.898 20.9507C145.952 20.3084 146.774 19.4476 147.365 18.3685C147.956 17.2637 148.251 16.0304 148.251 14.6686C148.251 13.3069 147.956 12.0736 147.365 10.9688C146.774 9.86396 145.952 9.00323 144.898 8.38659C143.87 7.74425 142.714 7.42308 141.43 7.42308C140.145 7.42308 138.976 7.74425 137.922 8.38659C136.895 9.00323 136.072 9.86396 135.456 10.9688C134.865 12.0736 134.569 13.3069 134.569 14.6686C134.569 16.0304 134.865 17.2637 135.456 18.3685C136.072 19.4476 136.895 20.3084 137.922 20.9507C138.976 21.5673 140.145 21.8757 141.43 21.8757Z"
          fill="white"
        />
      </svg> */}
      <svg
        width="111"
        height="41"
        viewBox="0 0 111 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M90.7177 39.5646H20.2823C15.1683 39.5646 10.2638 37.533 6.64765 33.9169C3.03152 30.3008 1 25.3963 1 20.2823C1 15.1683 3.03152 10.2638 6.64765 6.64765C10.2638 3.03152 15.1683 1 20.2823 1H90.7177C93.2499 1 95.7573 1.49875 98.0967 2.46778C100.436 3.4368 102.562 4.85712 104.352 6.64765C106.143 8.43817 107.563 10.5638 108.532 12.9033C109.501 15.2427 110 17.7501 110 20.2823C110 22.8145 109.501 25.3219 108.532 27.6613C107.563 30.0007 106.143 32.1264 104.352 33.9169C102.562 35.7074 100.436 37.1278 98.0967 38.0968C95.7573 39.0658 93.2499 39.5646 90.7177 39.5646Z"
          fill="#14007E"
          stroke="#E9FF00"
        />
        <path
          d="M29.8071 8.51562C36.4525 8.51562 41.9579 13.8924 41.9579 20.5056C41.9579 27.1189 36.4471 32.4956 29.8071 32.4956C23.167 32.4956 17.6562 27.1189 17.6562 20.5056C17.6562 13.8924 23.1581 8.51562 29.8071 8.51562ZM29.8071 28.6324C34.0956 28.6324 37.6461 25.1819 37.6461 20.4949C37.6461 15.8079 34.0938 12.3574 29.8071 12.3574C25.5203 12.3574 21.9698 15.8079 21.9698 20.4949C21.9698 25.1819 25.5221 28.6324 29.8071 28.6324Z"
          fill="#E9FF00"
        />
        <path
          d="M56.2487 25.3563H52.5928C52.7715 25.7583 52.8501 26.4784 52.8501 26.8805C52.8501 28.0026 52.1353 28.7656 51.0364 28.7656C49.7284 28.7656 49.2656 27.9008 49.2656 26.7768V17.167H54.7352V13.7719H49.2656V8.88477H44.8145V26.1497C44.8145 31.1851 47.8236 32.4842 50.8613 32.4842C54.8067 32.4842 56.6508 30.0933 56.6508 27.4166C56.6508 27.0878 56.5722 26.046 56.2487 25.3563Z"
          fill="#E9FF00"
        />
        <path
          d="M71.2565 25.3563H67.5988C67.7775 25.7583 67.8579 26.4784 67.8579 26.8805C67.8579 28.0026 67.1431 28.7656 66.0442 28.7656C64.7362 28.7656 64.2734 27.9008 64.2734 26.7768V17.167H69.7413V13.7719H64.2734V8.88477H59.8223V26.1497C59.8223 31.1851 62.8296 32.4842 65.8691 32.4842C69.8145 32.4842 71.6586 30.0933 71.6586 27.4166C71.6479 27.0878 71.5818 26.046 71.2565 25.3563Z"
          fill="#E9FF00"
        />
        <path
          d="M83.4524 14.4277C78.4491 14.4277 74.0784 18.4643 74.0784 23.4604C74.0784 28.4869 78.4562 32.4949 83.4524 32.4949C88.5093 32.4949 92.8567 28.4566 92.8567 23.4604C92.8567 18.4965 88.5093 14.4277 83.4524 14.4277ZM83.4524 28.889C80.5469 28.889 78.3222 26.4999 78.3222 23.4497C78.3222 20.3995 80.5469 18.0122 83.4524 18.0122C86.3989 18.0122 88.6129 20.4424 88.6129 23.4497C88.6129 26.4999 86.3882 28.889 83.4524 28.889Z"
          fill="#E9FF00"
        />
        <path
          d="M88.3956 8.51562L85.3579 12.4914H81.248L84.1071 8.51562H88.3956Z"
          fill="#E9FF00"
        />
      </svg>
      <div className="socials">
        <a
          href="https://www.ottoblockchain.com/"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_171_429)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.384 22.7789C18.706 23.0069 19.121 23.0639 19.491 22.9239C19.861 22.7829 20.133 22.4669 20.215 22.0839C21.084 17.9999 23.192 7.6629 23.983 3.9479C24.043 3.6679 23.943 3.3769 23.723 3.1899C23.503 3.0029 23.198 2.9489 22.926 3.0499C18.733 4.6019 5.82 9.4469 0.542001 11.3999C0.207001 11.5239 -0.0109989 11.8459 1.12521e-06 12.1989C0.0120011 12.5529 0.250001 12.8599 0.593001 12.9629C2.96 13.6709 6.067 14.6559 6.067 14.6559C6.067 14.6559 7.519 19.0409 8.276 21.2709C8.371 21.5509 8.59 21.7709 8.879 21.8469C9.167 21.9219 9.475 21.8429 9.69 21.6399C10.906 20.4919 12.786 18.7169 12.786 18.7169C12.786 18.7169 16.358 21.3359 18.384 22.7789ZM7.374 14.1019L9.053 19.6399L9.426 16.1329C9.426 16.1329 15.913 10.2819 19.611 6.9469C19.719 6.8489 19.734 6.6849 19.644 6.5699C19.555 6.4549 19.391 6.4279 19.268 6.5059C14.982 9.2429 7.374 14.1019 7.374 14.1019Z"
                fill="#979798"
              />
            </g>
            <defs>
              <clipPath id="clip0_171_429">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </a>
        <a
          href="https://ottoblockchain.medium.com/"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_171_431)">
              <path
                d="M6.76884 5C10.5072 5 13.5374 8.09763 13.5374 11.9185C13.5374 15.7393 10.5069 18.8367 6.76884 18.8367C3.03073 18.8367 0 15.7393 0 11.9185C0 8.09763 3.0305 5 6.76884 5ZM17.5782 5.40527C19.4474 5.40527 20.9626 8.32104 20.9626 11.9185H20.9628C20.9628 15.515 19.4476 18.4317 17.5784 18.4317C15.7092 18.4317 14.194 15.515 14.194 11.9185C14.194 8.32197 15.709 5.40527 17.5782 5.40527ZM22.8097 6.08367C23.4669 6.08367 24 8.69596 24 11.9185C24 15.1401 23.4672 17.7533 22.8097 17.7533C22.1522 17.7533 21.6196 15.1408 21.6196 11.9185C21.6196 8.69619 22.1524 6.08367 22.8097 6.08367Z"
                fill="#979798"
              />
            </g>
            <defs>
              <clipPath id="clip0_171_431">
                <rect
                  width="24"
                  height="13.8947"
                  fill="white"
                  transform="translate(0 5)"
                />
              </clipPath>
            </defs>
          </svg>
        </a>
        <a
          href="https://discord.com/invite/EAc6rpmqAT"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            width="25"
            height="19"
            viewBox="0 0 25 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.5853 2.21858C19.06 1.51872 17.4244 1.0031 15.7143 0.707782C15.6831 0.702082 15.652 0.716326 15.636 0.744813C15.4256 1.11895 15.1926 1.60703 15.0295 1.99067C13.1901 1.71529 11.3602 1.71529 9.55852 1.99067C9.39532 1.59851 9.15385 1.11895 8.94255 0.744813C8.92651 0.717276 8.8954 0.703033 8.86425 0.707782C7.15506 1.00216 5.51944 1.51778 3.99323 2.21858C3.98002 2.22428 3.96869 2.23378 3.96118 2.24612C0.858757 6.88107 0.00887418 11.4021 0.425798 15.8671C0.427685 15.8889 0.439947 15.9098 0.456926 15.9231C2.50381 17.4263 4.48658 18.3389 6.43252 18.9437C6.46366 18.9532 6.49666 18.9418 6.51648 18.9162C6.97679 18.2876 7.38712 17.6248 7.73894 16.9277C7.7597 16.8869 7.73988 16.8385 7.69744 16.8223C7.0466 16.5755 6.42686 16.2744 5.83071 15.9326C5.78356 15.9051 5.77978 15.8376 5.82316 15.8053C5.94861 15.7113 6.0741 15.6135 6.19388 15.5148C6.21556 15.4967 6.24576 15.4929 6.27124 15.5043C10.1877 17.2924 14.4277 17.2924 18.2979 15.5043C18.3233 15.492 18.3535 15.4958 18.3762 15.5138C18.496 15.6126 18.6214 15.7113 18.7478 15.8053C18.7912 15.8376 18.7884 15.9051 18.7412 15.9326C18.1451 16.2811 17.5253 16.5755 16.8735 16.8214C16.8311 16.8375 16.8122 16.8869 16.833 16.9277C17.1924 17.6238 17.6027 18.2866 18.0545 18.9152C18.0734 18.9418 18.1073 18.9532 18.1385 18.9437C20.0938 18.3389 22.0766 17.4263 24.1235 15.9231C24.1414 15.9098 24.1527 15.8899 24.1546 15.868C24.6536 10.706 23.3189 6.22206 20.6164 2.24706C20.6098 2.23378 20.5985 2.22428 20.5853 2.21858ZM8.32379 13.1484C7.14468 13.1484 6.17312 12.0659 6.17312 10.7364C6.17312 9.40698 7.12584 8.32447 8.32379 8.32447C9.53115 8.32447 10.4933 9.41648 10.4744 10.7364C10.4744 12.0659 9.52171 13.1484 8.32379 13.1484ZM16.2755 13.1484C15.0964 13.1484 14.1249 12.0659 14.1249 10.7364C14.1249 9.40698 15.0776 8.32447 16.2755 8.32447C17.4829 8.32447 18.445 9.41648 18.4262 10.7364C18.4262 12.0659 17.4829 13.1484 16.2755 13.1484Z"
              fill="#979798"
            />
          </svg>
        </a>
        <a
          href="https://twitter.com/OttoBlockchain?s=20&t=a0EEZCu3KYRVPx8rfpp14w"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.46 6C21.69 6.35 20.86 6.58 20 6.69C20.88 6.16 21.56 5.32 21.88 4.31C21.05 4.81 20.13 5.16 19.16 5.36C18.37 4.5 17.26 4 16 4C13.65 4 11.73 5.92 11.73 8.29C11.73 8.63 11.77 8.96 11.84 9.27C8.28 9.09 5.11 7.38 3 4.79C2.63 5.42 2.42 6.16 2.42 6.94C2.42 8.43 3.17 9.75 4.33 10.5C3.62 10.5 2.96 10.3 2.38 10C2.38 10 2.38 10 2.38 10.03C2.38 12.11 3.86 13.85 5.82 14.24C5.46 14.34 5.08 14.39 4.69 14.39C4.42 14.39 4.15 14.36 3.89 14.31C4.43 16 6 17.26 7.89 17.29C6.43 18.45 4.58 19.13 2.56 19.13C2.22 19.13 1.88 19.11 1.54 19.07C3.44 20.29 5.7 21 8.12 21C16 21 20.33 14.46 20.33 8.79C20.33 8.6 20.33 8.42 20.32 8.23C21.16 7.63 21.88 6.87 22.46 6Z"
              fill="#979798"
            />
          </svg>
        </a>
      </div>
      <div className="details">
        Otto Blockchain © {new Date().getFullYear()}
      </div>
    </div>
  );
}
